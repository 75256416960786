////
/// @group _blocks/
////

/// Description
.magnific-popup {
	position: relative;
	max-width: 96%;
	margin: 1rem auto;
	background-color: map-get($colors, white);
	padding: map-get($gutters, def);
	border-radius: map-get($gutters, sm);

	&--sm {
		width: map-get($breakpoints, sm);
	}
}
