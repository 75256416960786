.a_pf-wrap .a_pf-placeholder, .a_pf-wrap .a_pf-tip {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 14px
}

.a_pf-txt-pass, .a_pf-txt-clear {
	outline: 0
}

.a_pf-txt-pass::-ms-clear, .a_pf-txt-pass::-ms-reveal, .a_pf-txt-clear::-ms-clear, .a_pf-txt-clear::-ms-reveal {
	display: none
}

.a_pf-wrap .a_pf-btn {
	display: none;
	width: 25px;
	text-align: center;
	color: #9e9e9e;
	cursor: pointer;
	user-select: none;
	-moz-user-select: none;
	-khtml-user-select: none;
	-webkit-user-select: none;
	-o-user-select: none
}

.a_pf-wrap .a_pf-btn:hover {
	color: #4b4b4b
}

.a_pf-wrap .a_pf-btn.a_pf-btn-gen {
	width: 22px
}

.a_pf-wrap .a_pf-btn-gen-i {
	width: 16px;
	height: 16px;
	display: block;
	margin: 0 auto
}

.a_pf-wrap .a_pf-btn-gen-i, .a_pf-wrap .a_pf-btn-gen-help {
	height: 16px;
	background: url(../img/rand.png) center -16px no-repeat
}

.a_pf-wrap .a_pf-btn-gen-i:hover {
	background-position: center 0
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
	.a_pf-wrap .a_pf-btn-gen-i, .a_pf-wrap .a_pf-btn-gen-help {
		background-image: url(../img/rand@2x.png);
		background-size: 16px
	}
}

.a_pf-wrap .a_pf-placeholder {
	display: block;
	color: #aaa;
	text-align: left;
	overflow: hidden;
	cursor: text
}

.a_pf-wrap .a_pf-warn {
	color: #b54848;
	display: inline-block;
	position: absolute;
	right: 0;
	top: 0;
	font-size: 0.85rem;
	margin: 0
}

.a_pf-warn.a_pf-empty {
	display: none
}

.a_pf-wrap.a_pf-wrap-no-ib .a_pf-warn {
	display: inline;
	padding-bottom: 2px
}

.a_pf-wrap .a_pf-tip {
	display: none;
	line-height: 18px;
	margin: 0;
	padding: 0;
	z-index: 10000
}

.a_pf-wrap .a_pf-tip-arr-wrap {
	height: 9px;
	margin: 0
}

.a_pf-wrap.a_pf-wrap-no-ib .a_pf-tip-arr-wrap {
	display: none
}

.a_pf-wrap .a_pf-tip-arr {
	display: block;
	top: 0;
	margin: 0 0 0 20px;
	padding: 0;
	border-color: transparent transparent #9e9e9e;
	border-style: solid;
	border-width: 0 10px 10px;
	width: 0;
	height: 0
}

.a_pf-wrap .a_pf-tip-arr-in {
	display: block;
	position: relative;
	top: -9px;
	margin: 0 0 0 20px;
	padding: 0;
	border-color: transparent transparent #fff;
	border-style: solid;
	border-width: 0 10px 10px;
	width: 0;
	height: 0
}

.a_pf-wrap .a_pf-tip-body {
	display: block;
	border: 1px solid #9e9e9e;
	border-radius: 3px;
	padding: 5px;
	background-color: #fff;
	color: #333;
	margin: 0
}

.a_pf-wrap .a_pf-btn-gen-help {
	display: inline-block;
	width: 16px;
	cursor: default;
	vertical-align: bottom;
	margin: 0 1px 2px;
	padding: 0
}

.a_pf-wrap.a_pf-wrap-no-ib .a_pf-btn-gen-help {
	display: inline
}

.a_pf-wrap.a_pf-wrap-no-ib .arrow {
	display: none
}