////
/// @group _settings/
////

/// Карта цветов
/// @type {Map}
/// @prop {Color} $colors.link
$colors: (
	// Основные
	black: #000,
	white: #fff,
	gray: #868686,
	bg: #e9e9e9,
	text: #234,

	/// Ссылки
	link: #27366a,
	link-visited: #010930,
	link-hover: #1155cc,
	link-active: #000,

	// Цветовые статусы
	primary: #f26622,
	secondary: #313131,
	accent: #fcfcca,
	success: #ebba10,
	info: #00b3fe,
	warning: #c89600,
	danger: #f00,

	// Custom colors
	main-blue: #27366a,
		medium-blue: #3e58a2,
		bright-blue: #1155cc,
	dark-blue: #010930,
	light-gray: #edeef0,
	rose-gray: #c1c3d1,
	dark-text: #333333,
	lighter-text: #414141,
	main-gray: #666666,
	gray-text: #888888,
	yellow-color: #f5b203,
	main-yellow: #f8c200,
	red-color: #eb3d00,
	purple-light: #8b9bca,
	light-blue: #6e9acb,
	green-color: #0c7439,
		pink-color: #b63c7d,
		purple-color: #7164a0,
		dark-yellow: #d39627,
		dark-red: #b54848,
		lemon-yellow: #eddf4d,
		light-yellow: #fafad2
);

